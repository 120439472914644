import Web3 from 'web3';
import React from 'react';
import { ethers } from "ethers";

import { forwardRef, useRef } from "react";

import AppTimer from './AppTimer';
import AppTimer1 from './AppTimer1';


import AbiToken from "./contracts/Mytoken.json";

import AbiPool1 from "./contracts/PoolV6.json";

import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import coinbaseWalletModule from '@web3-onboard/coinbase';


import { useState, useEffect } from "react";

import './SinglePool1.css';



import winner from './winner.png';


import wallet from './desconectar.png';
import update from './update.png';
import info from './info.png';




import dividends from './dividends.png';

import logotrans from './patronlogotrans.png';



import {
   Button,
   Input,
} from 'reactstrap';





function SinglePoolV6(arr) {


const web4 = arr.fourth;  
const contractAddressPool1 = arr.first;
const blockchain = arr.second;  
const namechain = arr.third;  
const theexplorer = arr.url;



const contractpool11 = new web4.eth.Contract(AbiPool1, contractAddressPool1);  

const [buttondeposit, setButtonDeposit] = useState("");
const [buttonwithdraw, setButtonWithdraw] = useState("");
const [buttonspin, setButtonspin] = useState("");
const [buttonwinner, setButtonwinner] = useState("");
const [buttondonation, setButtonDonation] = useState("");
const [buttoncollectdonation, setButtonCollectDonation] = useState("");
const [buttoncollectinterest, setButtonCollectInterest] = useState("");
const [acc, setAcc] = useState(0);
const [amount, setAmount] = useState(0);


const [collectinterest, setCollectInterest] = useState(0);
const [int, setint] = useState([0,0]);
const [datafront, setdatafront] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
const [datafrontacc, setdatafrontacc] = useState([0,0,0,0,0]);
const [chainid, setchainid] = useState(0);

const [poolinfo, setpoolinfo] = useState(0);
const [jackpotinfo, setjackpotinfo] = useState(0);

const [ownerinfo, setownerinfo] = useState(0);
const [depositsinfo, setdepositsinfo] = useState(1);


const [nametoken, setnametoken] = useState("");

const [owneraddress, setowneraddress] = useState();


const [addressyield, setaddressyield] = useState(0);
const [addressunderlying, setaddressunderlying] = useState(0);




const [mensaje, setmensaje] = useState("");
const [mensajeok, setmensajeok] = useState("");


const [lang, setlang] = useState(0);

const [decimalstoken, setdecimalstoken] = useState(0);

const[lotterydata, setlotterydata] = useState([]);



const [web3, setweb3] = useState();
const [isconnected, setisconnected] = useState(0);

const [theprovider, settheprovider] = useState();


const chain137 = "https://polygon-rpc.com/";
const chain80001 = "https://rpc.ankr.com/polygon_mumbai";


const injected = injectedModule();
const coinbaseWalletSdk = coinbaseWalletModule();

const onboard = Onboard({
  wallets: [injected, coinbaseWalletSdk],
  chains: [
    
    {
      id: '137',
      token: 'MATIC',
      label: 'Polygon',
      rpcUrl: chain137
    },
    {
      id: '80001',
      token: 'MATIC',
      label: 'Mumbai',
      rpcUrl: chain80001
    }
    ],

  appMetadata: {
    name: "cryptopatron",
    
    description: "Lend and Give",
    recommendedInjectedWallets: [
      { name: "MetaMask", url: "https://metamask.io" }
      
    ]
   
  }

})














    useEffect(() => {

      
      if (theprovider?.on) {
        const handleAccountsChanged = (accounts) => {
          setAcc(accounts[0]);
          fetchdata1(accounts[0]);

        };
    
        const handleChainChanged = (chainId) => {
          setchainid(chainId);
        };
    
    
        theprovider.on("accountsChanged", handleAccountsChanged);
        theprovider.on("chainChanged", handleChainChanged);
    
    
        return () => {
          if (theprovider.removeListener) {
            theprovider.removeListener("accountsChanged", handleAccountsChanged);
            theprovider.removeListener("chainChanged", handleChainChanged);
          }
        };
      }


  
     
     
  }, [theprovider]);




  useEffect(() => {


  if (arr.thelang == 1) {
    setlang(1);
  } else {

    if (arr.thelang == 2) {
      setlang(2);
    } else {

      setlang(1);
    } 
  }  



    const fetchdata = async () => {


  setaddressyield(await contractpool11.methods.getPoolAddress().call()); 
  setaddressunderlying(await contractpool11.methods.underlying().call()); 

  setdecimalstoken(await contractpool11.methods.decimalsToken().call()); 
  setnametoken(await contractpool11.methods.nameToken().call()); 

  setint(await contractpool11.methods.calculateInterest().call());   

  setdatafront(await contractpool11.methods.pullData().call());
  setowneraddress(await contractpool11.methods.owner().call());

   }

   fetchdata();

    
   
   
}, []);




















  const fetchdata1 = async (x) => {
  setdatafrontacc(await contractpool11.methods.pullDataAccount(x).call());  
  }  


const HandleSetEnglish = () => {
  setlang(1);
}

const HandleSetSpanish = () => {
  setlang(2);
}



function HandleSetRefresh() {
      

  if (ownerinfo == 1) {
    HandleSetOwnerInfo();
  }    
   
  if (depositsinfo == 1) {
    HandleSetDepositsInfo();
  }
  
  if (jackpotinfo == 1) {
    HandleSetJackpotInfo();
  }
  
  if (poolinfo == 1) {
    HandleSetPoolInfo();
  }

}


const disconnect = async () => {


  const [primaryWallet] = await onboard.state.get().wallets;
  if (primaryWallet) await onboard.disconnectWallet({ label: primaryWallet.label });




  setmensaje("");
  setmensajeok("");


  if (ownerinfo == 1) {
    setdepositsinfo(1);
    setownerinfo(0);
  }

  setAcc(0);
  setisconnected(0);
  settheprovider("");


  setdatafrontacc([0, 0, 0, 0, 0]);

}


const connectMetamask = async () => {


  setmensaje("");
  setmensajeok("");




  const wallets = await onboard.connectWallet();

  
  if (wallets[0]) {
    // create an ethers provider with the last connected wallet provider
    
    const { accounts, chains, provider } = wallets[0];

    setweb3(new Web3(provider));

    setisconnected(1);  
    setAcc(accounts[0].address);
    
    settheprovider(provider);
    setchainid(chains[0].id);

    setdatafrontacc(await contractpool11.methods.pullDataAccount(accounts[0].address).call());

  }    








/*
  if(window.ethereum) {
  
    
  setweb3(new Web3(window.ethereum));

  settheprovider(window.ethereum);

  var network = await window.ethereum.request({ method: 'eth_chainId' });
  setchainid(network); 

  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  const account = accounts[0];
  
  if(accounts.length > 0) {
  setAcc(account);
  setisconnected(1);  
    
  setdatafrontacc(await contractpool11.methods.pullDataAccount(account).call());

}

  
  } else {
  
    if(lang == 1) {
      setmensaje("Install Metamask");
    }
    if(lang == 2) {
      setmensaje("Instale Metamask");
    }
  }  
*/


}


function clearalert() {
  setmensaje("");
  setmensajeok("");
  }
  


  function HandlePoolLoaded() {

    var verified = 0;
      
    if (web4.utils.isAddress(addressunderlying) == true && decimalstoken > 0) {
      verified = 1;
    } else {


      if (lang == 1) {  
        setmensaje("Pool is not fully loaded. Wait or Update or Refresh.");
      }  
    
      if (lang == 2) {  
        setmensaje("El pool no está totalmente cargado. Espere o Actualice o Refresque");
      }  


    }  
    
    return verified;
  
  }







  function HandleVerification() {

    var verified = 0;
    var theaddress = acc;
  
    if (web4.utils.isAddress(theaddress) == true) {
      verified = 1;
    }  
    
    return verified;
  
  }

  

  function HandleVerificationSend() {

    var verified = 0;  
  
    var connected = isconnected;
    var network = chainid;
    var account = acc;  
    
      if (connected == 0) {
  
  
        if (lang == 1) {  
          setmensaje("Connect Wallet");
        }  
      
        if (lang == 2) {  
          setmensaje("Conectar Billetera");
        }  
  
  
      } else {
        
        if (web4.utils.isAddress(account) == false) {
        
          if (lang == 1) {  
            setmensaje("Connect Wallet");
          }  
        
          if (lang == 2) {  
            setmensaje("Conectar Billetera");
          }  
          
        
        } else {
  
  
          if (network != blockchain) {
  
  
            if (lang == 1) {
            setmensaje("Switch to " + namechain + " network");
            }  
  
            if (lang == 2) {
              setmensaje("Cambiar a Red " + namechain);
            }
  
  
          } else {
  
          verified = 1;
          
          
      }
        }
      }   
  
      return verified;
  
    }      
  
  














const HandleSetDepositsInfo = async () => {
  
    setmensaje("");
    setmensajeok("");
  
    setButtonDeposit("");
    setButtonWithdraw("");
    setButtonspin("");
    setButtonwinner("");
    setButtonDonation("");
    setButtonCollectDonation("");
    setButtonCollectInterest("");
    setAmount(0);
    setCollectInterest(0);     

    var verificado = HandleVerification();

  
  if (depositsinfo == 0) {
    setdepositsinfo(1);
    setpoolinfo(0);
    setjackpotinfo(0);
    setownerinfo(0);
    
  }

  
  if (verificado == 1) {
    var account = acc;
    setdatafrontacc(await contractpool11.methods.pullDataAccount(account).call());
  }

  }




const HandleSetPoolInfo = async () => {
  


    setmensaje("");
    setmensajeok("");


    setButtonDeposit("");
    setButtonWithdraw("");
    setButtonspin("");
    setButtonwinner("");
    setButtonDonation("");
    setButtonCollectDonation("");
    setButtonCollectInterest("");
    setAmount(0);
    setCollectInterest(0);     
  



  if (poolinfo == 0) {
    setpoolinfo(1);
    setjackpotinfo(0);
    setownerinfo(0);
    setdepositsinfo(0);
    
  }

  
  setdatafront(await contractpool11.methods.pullData().call());
  setint(await contractpool11.methods.calculateInterest().call());   
  
  }







    const HandleSetOwnerInfo = async () => {
  


        setmensaje("");
        setmensajeok("");
      
        setButtonDeposit("");
        setButtonWithdraw("");
        setButtonspin("");
        setButtonwinner("");
        setButtonDonation("");
        setButtonCollectDonation("");
        setButtonCollectInterest("");
        setAmount(0);
        setCollectInterest(0);       


        var verificado = HandleVerification();
          
          if (verificado == 1) {

            var account = acc;  

            var checkowner = await contractpool11.methods.verifyOwner(account).call();

              if (checkowner == 2) {
                
                if (ownerinfo == 1) {
                  setownerinfo(0);
                  setdepositsinfo(1);
                  setjackpotinfo(0);
                  setpoolinfo(0);
                }  
              
              } else {
                setownerinfo(1);
                setdepositsinfo(0);
                setjackpotinfo(0);
                setpoolinfo(0);
              }
          
          } else {
              if (ownerinfo == 1) {
                setownerinfo(0);
                setjackpotinfo(0);
                setpoolinfo(0);
                setdepositsinfo(1);
              }  
          }

        
      setint(await contractpool11.methods.calculateInterest().call());   
      setdatafront(await contractpool11.methods.pullData().call());
          
}

const HandleSetJackpotInfo = async () => {
  

  
      setmensaje("");
      setmensajeok("");
    
      setButtonDeposit("");
      setButtonWithdraw("");
      setButtonspin("");
      setButtonwinner("");
      setButtonDonation("");
      setButtonCollectDonation("");
      setButtonCollectInterest("");
      setAmount(0);
      setCollectInterest(0);     


      if (jackpotinfo == 0) {
        setjackpotinfo(1);
        setpoolinfo(0);
        setownerinfo(0);
        setdepositsinfo(0);
        
      }
    

      setint(await contractpool11.methods.calculateInterest().call());   
      setdatafront(await contractpool11.methods.pullData().call());
      


      var lotteryresults1 = [];
      lotteryresults1 = await contractpool11.methods.getLotteryResults().call();
    
    
      if (lotteryresults1.length > 0) {
        const ppp = [];
            var x = lotteryresults1.length - 1;
            var theresult;
            var theamount;
            var thedate = [];
        
            while (x > -1) {
        
              if (lotteryresults1[x][2] == 1) {
                if (lang == 1) {
                theresult = "Awarded";
                }
                if (lang == 2) {
                  theresult = "Premiado";
                }
              }
            
              if (lotteryresults1[x][2] == 2) {
                if (lang == 1) {
                theresult = "Vacant";
                }
                if (lang == 2) {
                  theresult = "Vacante";
                }
              }
        
          
          theamount = ethers.utils.formatUnits(lotteryresults1[x][4], decimalstoken);
          
        
          thedate = new Date(lotteryresults1[x][1] * 1000);
          var theformatdate = thedate.getFullYear() + '/' + (thedate.getMonth() + 1) + '/' + (thedate.getDate());
        
        ppp.push(<tr>
        <td><div class="text text-start"><small>{lotteryresults1[x][0]}</small></div></td>
        <td><div class="text text-start"><small>{theformatdate}</small></div></td>
        <td><div class="text text-start"><small>{theresult}</small></div></td>
        
        {lotteryresults1[x][2] == 1 &&
        <td><div class="text text-start"><small><a href={'https://' + theexplorer +'/address/' + lotteryresults1[x][3]} target="_blank" rel="noopener noreferrer">{lotteryresults1[x][3].substr(0,6)}...{lotteryresults1[x][3].substr(-4)}</a></small></div></td>}
        
        {lotteryresults1[x][2] == 2 &&
        <td><div class="text text-start"><small></small></div></td>}
        



        <td><div class="text text-end"><small>{theamount}</small></div></td>
        </tr>);
        
        x = x - 1;
            
        }
        
        setlotterydata(ppp);
        
        }
    
      }


    const getUserInterest = async (e) => {
        e.preventDefault();
 
        var theamount = collectinterest;
        var theamountfixed = 0;
        var errorpay = 0;


        setmensaje("");
        setmensajeok("");

        var loadedpool = HandlePoolLoaded();

        if (loadedpool == 1) {


        var verificado = HandleVerificationSend();

        if (verificado == 1) {
                  
        var account = acc;  
    
        setButtonCollectInterest(true);      
        setButtonCollectDonation(true);      
    

    
    var checkowner =  await contractpool11.methods.verifyOwner(account).call();

    if (checkowner == 2) {
      setownerinfo(0);
      setdepositsinfo(1);
    
    } else {



      

    if (theamount <= 0 || theamount === undefined || isNaN(theamount)) {

    //if (collectinterest <= 0) {
  
      if (lang == 1) {
      setmensaje("Amount to collect must be greater than zero");
      }
    
      if (lang == 2) {
        setmensaje("El monto a retirar debe ser mayor a cero.");
        }
    
      setButtonCollectInterest("");
      setButtonCollectDonation("");       
    
    
    } else {



      try{
        theamountfixed = ethers.utils.parseUnits(theamount, decimalstoken);
        }catch(e){
        
          errorpay = 1;  
  
      setButtonCollectInterest("");
      setButtonCollectDonation("");       
    
      
          if (lang == 1) {
            setmensaje("Maximo admitido " + decimalstoken + " decimales");
            }  
          
            if (lang == 2) {
            setmensaje("Maximum " + decimalstoken + " decimals"); 
            }
        
        };


        if (errorpay == 0) {

          theamountfixed = String(theamountfixed);




var interests = [];
interests = await contractpool11.methods.calculateInterest().call();



var checkoperations = await contractpool11.methods.checkOperations(theamountfixed, interests[0], account, 5).call();

  

if (checkoperations == 6) {


  if (lang == 1) {
    setmensaje("You are not the owner");
    }

    if (lang == 2) {
      setmensaje("Usted no es el administrador");
      }

    setButtonCollectInterest("");  
    setButtonCollectDonation("");      

    setownerinfo(0);
    setdepositsinfo(1);


    } else {



  if (checkoperations == 8) {

    if (lang == 1) {
    setmensaje("Not enough interests to withdraw");
    }  


    if (lang == 2) {
      setmensaje("No hay suficientes intereses para retirar");
      }  

          setButtonCollectInterest("");  
          setButtonCollectDonation("");       
        

        } else {



          if (lang == 1) {  
        setmensajeok("Confirm interests withdraw");
          }

          if (lang == 2) {  
            setmensajeok("Confirme retiro de intereses");
              }

              
            
  

              var gasAmount = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.withdrawYield(theamountfixed).estimateGas({ 
                from: account});  
             

                gasAmount = Math.trunc(gasAmount * 1.25);



                var toBN = Web3.utils.toBN;


                const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
                const gasStationObj = JSON.parse(await gasStationResponse.text());
                
                var block_number = gasStationObj.blockNumber;
              
                var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
                var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
                
                base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
                max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
                  
                var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
                var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
                max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
            
            
            
        




try{
      const result = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.withdrawYield(theamountfixed).send({ 
        from: account,
        gas: gasAmount,
        maxFeePerGas: max_fee_per_gas,
        maxPriorityFeePerGas: max_priority_fee })

        

    
        .once('transactionHash', function(transactionHash) {
      
          setmensaje("");
          setmensajeok("");  
    
    
    
          if(lang == 1) {
            setmensajeok("Transaction sent. Pending...");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion enviada. Pendiente...");
            }
               
        })
    
        
        .once('confirmation', function(confirmationNumber, receipt){
    
          setmensaje("");
          setmensajeok("");  
    
    
    
          if (receipt.status) {
    
    
            setCollectInterest(0);            
            setButtonCollectDonation("");    
            setButtonCollectInterest("");      
        
      
    
    
    
              if(lang == 1) {
              setmensajeok("Transaction confirmed");
              }  
          
              if(lang == 2) {
                setmensajeok("Transaccion confirmada");
              }
          
          
        } else {
    
          setCollectInterest(0);            
          setButtonCollectDonation("");    
          setButtonCollectInterest("");      
      
    
    
    
          if(lang == 1) {
            setmensaje("Transaction Failed");
            }
    
            if(lang == 2) {
            setmensaje("Transaccion Fallida");
            }
    
    
    
        }
        
    
        })
    
        .once('error', function(error, receipt){
    
    
          setmensaje("");
          setmensajeok("");  
    
          setCollectInterest(0);            
          setButtonCollectDonation("");    
          setButtonCollectInterest("");      
      
    
    
          if(lang == 1) {
            setmensaje("Transaction Failed");
            }
    
            if(lang == 2) {
            setmensaje("Transaccion Fallida");
            }
    
        });  
    
      }catch(e){
        
      }





        
    
    


    }  



    setdatafrontacc(await contractpool11.methods.pullDataAccount(account).call());
    setdatafront(await contractpool11.methods.pullData().call());
    setint(await contractpool11.methods.calculateInterest().call());   





}
}
}
}
}
}
}
        



const getPrize = async () => {

  
setmensaje("");
setmensajeok("");

var verificado = HandleVerificationSend();

if (verificado == 1) {
          
  var account = acc;  

  setButtonspin(true);


  var checkprize = await contractpool11.methods.checkJackpotReady().call();

  
if (checkprize == 1) {


  var interests = [];
  interests = await contractpool11.methods.calculateInterest().call();

  
  if (interests[1] <= 0) {

if (lang == 1) {    
setmensaje("No accumulated prize amount");
}

if (lang == 2) {    
  setmensaje("No hay monto de premio acumulado.");
  }

    setButtonspin("");

  } else {



      if (lang == 1) {
        setmensajeok("Confirm spinning the wheel");  
      }
      
      if (lang == 2) {
        setmensajeok("Confirme girar la rueda.");  
      }
      

 


      var gasAmount = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.settleJackpot().estimateGas({ 
        from: account});


        gasAmount = Math.trunc(gasAmount * 1.25);
 
        //var theGasPrice = await web3.eth.getGasPrice();


        var toBN = Web3.utils.toBN;


        const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
        const gasStationObj = JSON.parse(await gasStationResponse.text());
        
        var block_number = gasStationObj.blockNumber;
      
        var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
        var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
        
        base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
        max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
          
        var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
        var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
        max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
    
    
    



try{
    const result = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.settleJackpot().send({ 
      from: account,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })
  
      
      

      .once('transactionHash', function(transactionHash) {
      
        setmensaje("");
        setmensajeok("");  



        if(lang == 1) {
          setmensajeok("Transaction sent. Pending...");
          }  
      
          if(lang == 2) {
            setmensajeok("Transaccion enviada. Pendiente...");
          }
             
      })
  
      
      .once('confirmation', function(confirmationNumber, receipt){

        setmensaje("");
        setmensajeok("");  



        if (receipt.status) {

            if(lang == 1) {
            setmensajeok("Transaction confirmed");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion confirmada");
            }
        
        
      } else {


        setButtonspin("");
        
        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }



      }
      

      })

      .once('error', function(error, receipt){


        setmensaje("");
        setmensajeok("");  

        setButtonspin("");


        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }
  
      });  

    }catch(e){

    }






     
     
     
     
      

    setdatafront(await contractpool11.methods.pullData().call());
    setint(await contractpool11.methods.calculateInterest().call());   

    setButtonspin("");    
       
    
  }
    
    
    } else {
      
      if (lang == 1) {
      setmensaje("Not Ready");
      }

      if (lang == 2) {
        setmensaje("No disponible");
        }
    
      setButtonspin("");
  
    }

  
  
  

  }

    }


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



const getAwinner = async () => {
  
        
setmensaje("");
setmensajeok("");


var verificado = HandleVerificationSend();

if (verificado == 1) {
          
  var account = acc;  

  setButtonwinner(true);



var checkprize = await contractpool11.methods.checkWinnerReady().call();


if (checkprize == 1) {

 
if (lang == 1) {      
setmensajeok("Confirm pick a winner");
}

if (lang == 2) {      
  setmensajeok("Confirme elegir un ganador.");
  }
  





  var gasAmount = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.pickWinner().estimateGas({ 
    from: account});
    

    gasAmount = Math.trunc(gasAmount * 1.25);

    //var theGasPrice = await web3.eth.getGasPrice();

    var toBN = Web3.utils.toBN;


    const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
    const gasStationObj = JSON.parse(await gasStationResponse.text());
    
    var block_number = gasStationObj.blockNumber;
  
    var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
    var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
    
    base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
    max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
      
    var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
    var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
    max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));







try{
    const result = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.pickWinner().send({ 
      from: account,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })
  
      


      .once('transactionHash', function(transactionHash) {
      
        setmensaje("");
        setmensajeok("");  



        if(lang == 1) {
          setmensajeok("Transaction sent. Pending...");
          }  
      
          if(lang == 2) {
            setmensajeok("Transaccion enviada. Pendiente...");
          }
             
      })
  
      
      .once('confirmation', function(confirmationNumber, receipt){

        setmensaje("");
        setmensajeok("");  



        if (receipt.status) {

            if(lang == 1) {
            setmensajeok("Transaction confirmed");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion confirmada");
            }
        
        
      } else {

        setButtonwinner("");


        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }



      }
      

      })

      .once('error', function(error, receipt){


        setmensaje("");
        setmensajeok("");  

        setButtonwinner("");


        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }
  
      });  
    
    }catch(e){

    }

      


    setdatafront(await contractpool11.methods.pullData().call());
    setint(await contractpool11.methods.calculateInterest().call());   
        
    setButtonwinner("");
     

        var lotteryresults1 = [];
        lotteryresults1 = await contractpool11.methods.getLotteryResults().call();
      
      
        if (lotteryresults1.length > 0) {
          const ppp = [];
              var x = lotteryresults1.length - 1;
              var theresult;
              var theamount;
              var thedate = [];
          
              while (x > -1) {
          
                if (lotteryresults1[x][2] == 1) {
                  if (lang == 1) {
                  theresult = "Awarded";
                  }
                  if (lang == 2) {
                    theresult = "Premiado";
                  }
                }
              
                if (lotteryresults1[x][2] == 2) {
                  if (lang == 1) {
                  theresult = "Vacant";
                  }
                  if (lang == 2) {
                    theresult = "Vacante";
                  }
                }
          
            
              theamount = ethers.utils.formatUnits(lotteryresults1[x][4], decimalstoken);
                     
            
          
            thedate = new Date(lotteryresults1[x][1] * 1000);
            var theformatdate = thedate.getFullYear() + '/' + (thedate.getMonth() + 1) + '/' + (thedate.getDate());
          





          ppp.push(<tr>
          <td><div class="text text-start"><small>{lotteryresults1[x][0]}</small></div></td>
          <td><div class="text text-start"><small>{theformatdate}</small></div></td>
          <td><div class="text text-start"><small>{theresult}</small></div></td>
          
          
          {lotteryresults1[x][2] == 1 &&
        <td><div class="text text-start"><small><a href={'https://' + theexplorer +'/address/' + lotteryresults1[x][3]} target="_blank" rel="noopener noreferrer">{lotteryresults1[x][3].substr(0,6)}...{lotteryresults1[x][3].substr(-4)}</a></small></div></td>}
        
        {lotteryresults1[x][2] == 2 &&
        <td><div class="text text-start"><small></small></div></td>}
        
        <td><div class="text text-end"><small>{theamount}</small></div></td>
        </tr>);
          
          x = x - 1;
              
          }
          
          setlotterydata(ppp);
          
          }
      


    } else {

      if (lang == 1) {
      setmensaje("Try Again in a few blocks.");
      }  

      if (lang == 2) {
        setmensaje("Intenta nuevamente en los siguientes bloques.");
      }  
  
      setButtonwinner("");
 
    }



 
    }

  }


  const getDonations = async (e) => {
    e.preventDefault();


  var theamount = collectinterest;
  var theamountfixed = 0;
  var errorpay = 0;


    setmensaje("");
    setmensajeok("");


    var loadedpool = HandlePoolLoaded();

    if (loadedpool == 1) {


    var verificado = HandleVerificationSend();

    if (verificado == 1) {
              
    var account = acc;  
  
    setButtonCollectDonation(true);    
    setButtonCollectInterest(true);      
  
    
    var checkowner =  await contractpool11.methods.verifyOwner(account).call();

    if (checkowner == 2) {
      setownerinfo(0);
      setdepositsinfo(1);
  
    } else {

     

      if (theamount <= 0 || theamount === undefined || isNaN(theamount)) {


      if (lang == 1) {
      setmensaje("Amount to collect must be greater than zero");
      }
    
      if (lang == 2) {
        setmensaje("El monto a retirar debe ser mayor a cero.");
        }
      
      
      setButtonCollectDonation("");    
      setButtonCollectInterest("");      
    
    
    } else {
    

      try{
        theamountfixed = ethers.utils.parseUnits(theamount, decimalstoken);
        }catch(e){
        
          errorpay = 1;  
  
          
      setButtonCollectDonation("");    
      setButtonCollectInterest("");      
  

          if (lang == 1) {
            setmensaje("Maximo admitido " + decimalstoken + " decimales");
            }  
          
            if (lang == 2) {
            setmensaje("Maximum " + decimalstoken + " decimals"); 
            }
        
        };


 if (errorpay == 0) {

    theamountfixed = String(theamountfixed);


    
     var checkoperations = await contractpool11.methods.checkOperations(theamountfixed, 0, account, 4).call();
    

if (checkoperations == 6) {

  if (lang == 1) {
    setmensaje("You are not the owner");
    }
    
    if (lang == 2) {
      setmensaje("Usted no es el creador");
      }
            
    setButtonCollectDonation("");    
    setButtonCollectInterest("");      
  
    setownerinfo(0);
    setdepositsinfo(1);


    } else {

  
  if (checkoperations == 7) {

    if (lang == 1) {
    setmensaje("Not enough donations to collect");
    }

    if (lang == 2) {
      setmensaje("No tiene donaciones suficientes para retirar.");
      }

      setButtonCollectDonation("");    
      setButtonCollectInterest("");      
    


     } else {


      if (lang == 1) {
        setmensajeok("Confirm donations withdraw");
      }
     
      if (lang == 2) {
        setmensajeok("Confirme retiro de donaciones.");
      }
     

      


      var gasAmount = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.withdrawDonations(theamountfixed).estimateGas({ 
        from: account});


        gasAmount = Math.trunc(gasAmount * 1.25);
    


        var toBN = Web3.utils.toBN;


        const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
        const gasStationObj = JSON.parse(await gasStationResponse.text());
        
        var block_number = gasStationObj.blockNumber;
      
        var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
        var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
        
        base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
        max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
          
        var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
        var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
        max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
    
    



try{
  const result = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.withdrawDonations(theamountfixed).send({ 
    from: account,
    gas: gasAmount,
    maxFeePerGas: max_fee_per_gas,
    maxPriorityFeePerGas: max_priority_fee })


    
    .once('transactionHash', function(transactionHash) {
      
      setmensaje("");
      setmensajeok("");  



      if(lang == 1) {
        setmensajeok("Transaction sent. Pending...");
        }  
    
        if(lang == 2) {
          setmensajeok("Transaccion enviada. Pendiente...");
        }
           
    })

    
    .once('confirmation', function(confirmationNumber, receipt){

      setmensaje("");
      setmensajeok("");  



      if (receipt.status) {


        setCollectInterest(0);            
        setButtonCollectDonation("");    
        setButtonCollectInterest("");      
    
  



          if(lang == 1) {
          setmensajeok("Transaction confirmed");
          }  
      
          if(lang == 2) {
            setmensajeok("Transaccion confirmada");
          }
      
      
    } else {

      setCollectInterest(0);            
      setButtonCollectDonation("");    
      setButtonCollectInterest("");      
  



      if(lang == 1) {
        setmensaje("Transaction Failed");
        }

        if(lang == 2) {
        setmensaje("Transaccion Fallida");
        }



    }
    

    })

    .once('error', function(error, receipt){


      setmensaje("");
      setmensajeok("");  

      setCollectInterest(0);            
      setButtonCollectDonation("");    
      setButtonCollectInterest("");      
  


      if(lang == 1) {
        setmensaje("Transaction Failed");
        }

        if(lang == 2) {
        setmensaje("Transaccion Fallida");
        }

    });  
  
  }catch(e){

  }














}






setdatafrontacc(await contractpool11.methods.pullDataAccount(account).call());
setdatafront(await contractpool11.methods.pullData().call());
setint(await contractpool11.methods.calculateInterest().call());   
          
        
      



}
}
}
}
}
}
}


  


const handleSetDeposit = async (e) => {
  e.preventDefault();

  var theamount = amount;
  var theamountfixed = 0;
  var errorpay = 0;


  setmensaje("");
  setmensajeok("");

  
  var loadedpool = HandlePoolLoaded();

  if (loadedpool == 1) {


  var verificado = HandleVerificationSend();

  if (verificado == 1) {
            
    var account = acc;  


    setButtonDeposit(true);    
    setButtonWithdraw(true);
    setButtonDonation(true);



    if (theamount <= 0 || theamount === undefined || isNaN(theamount)) {
  
    if (lang == 1) {
    setmensaje("Fill Amount");
    }  

    if (lang == 2) {
      setmensaje("Ingrese Importe");
    }  
  
  
  setButtonDeposit("");
  setButtonWithdraw("");
  setButtonDonation("");


  } else {


    try{
      theamountfixed = ethers.utils.parseUnits(theamount, decimalstoken);
      }catch(e){
      
        errorpay = 1;  

        setButtonDeposit("");
        setButtonWithdraw("");
        setButtonDonation("");

        if (lang == 1) {
          setmensaje("Maximo admitido " + decimalstoken + " decimales");
          }  
        
          if (lang == 2) {
          setmensaje("Maximum " + decimalstoken + " decimals"); 
          }
      
      };


  if (errorpay == 0) {

    theamountfixed = String(theamountfixed);
  
  
    var checkoperations = await contractpool11.methods.checkOperations(theamountfixed, 0, account, 1).call();
  

if (checkoperations == 1) {
  
  if (lang == 1) {
  setmensaje("Deposits suspended");
  }

  if (lang == 2) {
    setmensaje("Depósitos suspendidos");
    }
  
  
  setButtonDeposit("");
  setButtonWithdraw("");
  setButtonDonation("");

} else {

  if (checkoperations == 2) {
    
    if (lang == 1) {
    setmensaje("Deposits suspended");
    }

    if (lang == 2) {
      setmensaje("Depósitos suspendidos");
      }

    setButtonDeposit("");
    setButtonWithdraw("");
    setButtonDonation("");
  
  } else {


    if (checkoperations == 3) {
    
      if (lang == 1) {
      setmensaje("Not enough funds");
      }

      if (lang == 2) {
        setmensaje("Fondos Insuficientes");
        }

      setButtonDeposit("");
      setButtonWithdraw("");
      setButtonDonation("");
    
    } else { 


      
  




        if (checkoperations == 4) {
  
          
          if (lang == 1) { 
         setmensajeok("Confirm APPROVE");
          }

          if (lang == 2) { 
            setmensajeok("Confirme APROBACION");
             }
   


    var gasAmount = await (new web3.eth.Contract(AbiToken, await contractpool11.methods.underlying().call())).methods.approve(contractAddressPool1, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").estimateGas({ 
      from: account});


      gasAmount = Math.trunc(gasAmount * 1.25);
        
      //var theGasPrice = await web3.eth.getGasPrice();


      var toBN = Web3.utils.toBN;


      const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
      const gasStationObj = JSON.parse(await gasStationResponse.text());
      
      var block_number = gasStationObj.blockNumber;
    
      var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
      var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
      
      base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
      max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
        
      var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
      var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
      max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
  






try{
    const result = await (new web3.eth.Contract(AbiToken, await contractpool11.methods.underlying().call())).methods.approve(contractAddressPool1, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({ 
      from: account,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })
  
      



      .once('transactionHash', function(transactionHash) {
      
        setmensaje("");
        setmensajeok("");  



        if(lang == 1) {
          setmensajeok("Transaction sent. Pending...");
          }  
      
          if(lang == 2) {
            setmensajeok("Transaccion enviada. Pendiente...");
          }
             
      })
  
      
      .once('confirmation', function(confirmationNumber, receipt){

        setmensaje("");
        setmensajeok("");  



        if (receipt.status) {

          setButtonDeposit("");


            if(lang == 1) {
            setmensajeok("Transaction confirmed");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion confirmada");
            }
        
        
      } else {


        setButtonDeposit("");
        setButtonWithdraw("");
        setButtonDonation("");

        
        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }



      }
      

      })

      .once('error', function(error, receipt){


        setmensaje("");
        setmensajeok("");  

        setButtonDeposit("");
        setButtonWithdraw("");
        setButtonDonation("");



        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }
  
      });  
    }catch(e){

    }



      
      
  

  } else {

    
    
    if (lang == 1) { 
      setmensajeok("Confirm deposit");    
    }

    if (lang == 2) { 
      setmensajeok("Confirme depósito");    
    }



    var gasAmount = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.deposit(theamountfixed).estimateGas({ 
      from: account});


      gasAmount = Math.trunc(gasAmount * 1.25);

      //var theGasPrice = await web3.eth.getGasPrice();


      var toBN = Web3.utils.toBN;


      const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
      const gasStationObj = JSON.parse(await gasStationResponse.text());
      
      var block_number = gasStationObj.blockNumber;
    
      var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
      var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
      
      base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
      max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
        
      var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
      var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
      max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
  






try{
    const result = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.deposit(theamountfixed).send({ 
      from: account,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })
  
      
      
      .once('transactionHash', function(transactionHash) {
      
        setmensaje("");
        setmensajeok("");  



        if(lang == 1) {
          setmensajeok("Transaction sent. Pending...");
          }  
      
          if(lang == 2) {
            setmensajeok("Transaccion enviada. Pendiente...");
          }
             
      })
  
      
      .once('confirmation', function(confirmationNumber, receipt){

        setmensaje("");
        setmensajeok("");  



        if (receipt.status) {


          setAmount(0);  
          setButtonDeposit("");
          setButtonWithdraw("");
          setButtonDonation("");



            if(lang == 1) {
            setmensajeok("Transaction confirmed");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion confirmada");
            }
        
        
      } else {


        setButtonDeposit("");
        setButtonWithdraw("");
        setButtonDonation("");


        
        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }



      }
      

      })

      .once('error', function(error, receipt){


        setmensaje("");
        setmensajeok("");  

        setButtonDeposit("");
        setButtonWithdraw("");
        setButtonDonation("");



        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }
  
      });  

    }catch(e){

    }










    
    }


  setdatafrontacc(await contractpool11.methods.pullDataAccount(account).call());
  
    
}
}
}
}
}
}
}
}

////////////////////////////////////////////////////////////////


const handleSetDepositDonation = async (e) => {
  e.preventDefault();

  var theamount = amount;
  var theamountfixed = 0;
  var errorpay = 0;



  setmensaje("");
  setmensajeok("");

  

  var loadedpool = HandlePoolLoaded();

  if (loadedpool == 1) {



  var verificado = HandleVerificationSend();

  if (verificado == 1) {
            
    var account = acc;  

    setButtonDeposit(true);    
    setButtonWithdraw(true);
    setButtonDonation(true);    


    




    if (theamount <= 0 || theamount === undefined || isNaN(theamount)) {
  
    if (lang == 1) {   
  setmensaje("Amount to deposit must be greater than zero");
    }

    if (lang == 2) {   
      setmensaje("El importe a depositar debe ser mayor a cero.");
        }


  setButtonDonation("");
  setButtonDeposit("");    
  setButtonWithdraw("");
  

} else {

  try{
    theamountfixed = ethers.utils.parseUnits(theamount, decimalstoken);
    }catch(e){
    
      errorpay = 1;  

      setButtonDeposit("");
      setButtonWithdraw("");
      setButtonDonation("");

      if (lang == 1) {
        setmensaje("Maximo admitido " + decimalstoken + " decimales");
        }  
      
        if (lang == 2) {
        setmensaje("Maximum " + decimalstoken + " decimals"); 
        }
    
    };


    if (errorpay == 0) {

      theamountfixed = String(theamountfixed);


      var checkoperations = await contractpool11.methods.checkOperations(theamountfixed, 0, account, 2).call();


if (checkoperations == 1) {
  
  if (lang == 1) { 
  setmensaje("Deposits are suspended");
  }

  if (lang == 2) { 
    setmensaje("Los depósitos están suspendidos");
    }
  

  setButtonDonation("");
  setButtonDeposit("");    
  setButtonWithdraw("");
  

} else {


  if (checkoperations == 3) {


    if (lang == 1) {   
    setmensaje("Not enough funds in your wallet");
    }

    if (lang == 2) {   
      setmensaje("No tiene suficientes fondos en su billetera.");
      }
  
      setButtonDonation("");
      setButtonDeposit("");    
      setButtonWithdraw("");
      
    
    } else { 



    
        if (checkoperations == 4) {
  
  
          if (lang == 1) {
            setmensajeok("Confirm APPROVE and then deposit");
          }

          if (lang == 2) {
            setmensajeok("Confirme APROBACION y luego deposite.");
          }




          var gasAmount = await (new web3.eth.Contract(AbiToken, await contractpool11.methods.underlying().call())).methods.approve(contractAddressPool1, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").estimateGas({ 
            from: account});


            gasAmount = Math.trunc(gasAmount * 1.25);            
      
            //var theGasPrice = await web3.eth.getGasPrice();


            var toBN = Web3.utils.toBN;


            const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
            const gasStationObj = JSON.parse(await gasStationResponse.text());
            
            var block_number = gasStationObj.blockNumber;
          
            var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
            var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
            
            base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
            max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
              
            var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
            var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
            max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
        





try{
    const result = await (new web3.eth.Contract(AbiToken, await contractpool11.methods.underlying().call())).methods.approve(contractAddressPool1, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({ 
      from: account,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })
  
      


      .once('transactionHash', function(transactionHash) {
      
        setmensaje("");
        setmensajeok("");  



        if(lang == 1) {
          setmensajeok("Transaction sent. Pending...");
          }  
      
          if(lang == 2) {
            setmensajeok("Transaccion enviada. Pendiente...");
          }
             
      })
  
      
      .once('confirmation', function(confirmationNumber, receipt){

        setmensaje("");
        setmensajeok("");  



        if (receipt.status) {


          setButtonDonation("");

            if(lang == 1) {
            setmensajeok("Transaction confirmed");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion confirmada");
            }
        
        
      } else {


        setButtonDonation("");
        setButtonDeposit("");    
        setButtonWithdraw("");
        
        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }



      }
      

      })

      .once('error', function(error, receipt){


        setmensaje("");
        setmensajeok("");  

        setButtonDonation("");
        setButtonDeposit("");    
        setButtonWithdraw("");



        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }
  
      });  

    }catch(e){

    }



      
      
      

  

  } else {

    
    
    if (lang == 1) {
      setmensajeok("Confirm donation (deposit not refundable)");
    }

    if (lang == 2) {
      setmensajeok("Confirme la donación (deposito no reembolsable)");
    }



    var gasAmount = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.depositDonation(theamountfixed).estimateGas({ 
      from: account});


      gasAmount = Math.trunc(gasAmount * 1.25);

      //var theGasPrice = await web3.eth.getGasPrice();


      var toBN = Web3.utils.toBN;


      const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
      const gasStationObj = JSON.parse(await gasStationResponse.text());
      
      var block_number = gasStationObj.blockNumber;
    
      var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
      var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
      
      base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
      max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
        
      var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
      var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
      max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
  








  try{
    const result = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.depositDonation(theamountfixed).send({ 
      from: account,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })
  
      
       
       .once('transactionHash', function(transactionHash) {
      
        setmensaje("");
        setmensajeok("");  



        if(lang == 1) {
          setmensajeok("Transaction sent. Pending...");
          }  
      
          if(lang == 2) {
            setmensajeok("Transaccion enviada. Pendiente...");
          }
             
      })
  
      
      .once('confirmation', function(confirmationNumber, receipt){

        setmensaje("");
        setmensajeok("");  



        if (receipt.status) {


          setAmount(0);  
          setButtonDonation("");
          setButtonDeposit("");    
          setButtonWithdraw("");



            if(lang == 1) {
            setmensajeok("Transaction confirmed");
            }  
        
            if(lang == 2) {
              setmensajeok("Transaccion confirmada");
            }
        
        
      } else {


        setButtonDonation("");
        setButtonDeposit("");    
        setButtonWithdraw("");

        
        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }



      }
      

      })

      .once('error', function(error, receipt){


        setmensaje("");
        setmensajeok("");  

        setButtonDonation("");
        setButtonDeposit("");    
        setButtonWithdraw("");




        if(lang == 1) {
          setmensaje("Transaction Failed");
          }
  
          if(lang == 2) {
          setmensaje("Transaccion Fallida");
          }
  
      });  

    }catch(e){

    }






      

  
  
  

  }  

  

  setdatafrontacc(await contractpool11.methods.pullDataAccount(account).call());
  

}
}
}
}
}
}
}





  const handleSetWithdraw = async (e) => {
    e.preventDefault(); 

    var theamount = amount;
    var theamountfixed = 0;
    var errorpay = 0;
  



  setmensaje("");
  setmensajeok("");



  var loadedpool = HandlePoolLoaded();

  if (loadedpool == 1) {


  var verificado = HandleVerificationSend();

  if (verificado == 1) {
            
    var account = acc;  

    setButtonWithdraw(true);
    setButtonDeposit(true);    
    setButtonDonation(true);    



    if (theamount <= 0 || theamount === undefined || isNaN(theamount)) {

    
    if (lang == 1) {
    setmensaje("Amount to withdraw must be greater than zero");
    }

    if (lang == 2) {
      setmensaje("El monto a retirar debe ser mayor a cero.");
      }
  
    
    setButtonWithdraw("");
    setButtonDeposit("");
    setButtonDonation("");            
    

  } else {


    try{
      theamountfixed = ethers.utils.parseUnits(theamount, decimalstoken);
      }catch(e){
      
        errorpay = 1;  

        setButtonDeposit("");
        setButtonWithdraw("");
        setButtonDonation("");

        if (lang == 1) {
          setmensaje("Maximo admitido " + decimalstoken + " decimales");
          }  
        
          if (lang == 2) {
          setmensaje("Maximum " + decimalstoken + " decimals"); 
          }
      
      };


      if (errorpay == 0) {

        theamountfixed = String(theamountfixed);


  var checkoperations = await contractpool11.methods.checkOperations(theamountfixed, 0, account, 3).call();


if (checkoperations == 2) {
  

if (lang == 1) {  
setmensaje("Withdraws are suspended while the Lottery is being drawn");
}

if (lang == 2) {  
  setmensaje("Los retiros están suspendidos mientras se está sorteando la lotería.");
  }


  setButtonWithdraw("");
  setButtonDeposit("");
  setButtonDonation("");        

} else {


    if (checkoperations == 5) {

      if (lang == 1) {
      setmensaje("Not enough funds to withdraw");
      }

      if (lang == 2) {
        setmensaje("No tiene fondos suficientes para retirar.");
        }


setButtonWithdraw("");
setButtonDeposit("");    
setButtonDonation("");        


} else {


  
    if (lang == 1) {
        setmensajeok("Confirm withdraw");
    }

    if (lang == 2) {
      setmensajeok("Confirme el retiro.");
  }






  var gasAmount = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.withdraw(theamountfixed).estimateGas({ 
    from: account});


    gasAmount = Math.trunc(gasAmount * 1.25);

    //var theGasPrice = await web3.eth.getGasPrice();

    var toBN = Web3.utils.toBN;


    const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
    const gasStationObj = JSON.parse(await gasStationResponse.text());
    
    var block_number = gasStationObj.blockNumber;
  
    var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
    var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
    
    base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
    max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
      
    var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
    var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
    max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
    



try{
  const result = await (new web3.eth.Contract(AbiPool1, contractAddressPool1)).methods.withdraw(theamountfixed).send({ 
    from: account,
    gas: gasAmount,
    maxFeePerGas: max_fee_per_gas,
    maxPriorityFeePerGas: max_priority_fee })
    
    
    .once('transactionHash', function(transactionHash) {
      
      setmensaje("");
      setmensajeok("");  



      if(lang == 1) {
        setmensajeok("Transaction sent. Pending...");
        }  
    
        if(lang == 2) {
          setmensajeok("Transaccion enviada. Pendiente...");
        }
           
    })

    
    .once('confirmation', function(confirmationNumber, receipt){

      setmensaje("");
      setmensajeok("");  



      if (receipt.status) {


        setAmount(0);  
        setButtonWithdraw("");
        setButtonDeposit("");    
        setButtonDonation("");          



          if(lang == 1) {
          setmensajeok("Transaction confirmed");
          }  
      
          if(lang == 2) {
            setmensajeok("Transaccion confirmada");
          }
      
      
    } else {


      setButtonWithdraw("");
      setButtonDeposit("");    
      setButtonDonation("");          


      
      if(lang == 1) {
        setmensaje("Transaction Failed");
        }

        if(lang == 2) {
        setmensaje("Transaccion Fallida");
        }



    }
    

    })

    .once('error', function(error, receipt){


      setmensaje("");
      setmensajeok("");  

      setButtonWithdraw("");
      setButtonDeposit("");    
      setButtonDonation("");          
      

      if(lang == 1) {
        setmensaje("Transaction Failed");
        }

        if(lang == 2) {
        setmensaje("Transaccion Fallida");
        }

    });  
  }catch(e){

  }



}



setdatafrontacc(await contractpool11.methods.pullDataAccount(account).call());



}
}
}
}
}
}



const switchNetwork = async () => {

  setmensaje("");

  await theprovider.request({
    method: 'wallet_switchEthereumChain',
      params: [{ chainId: Web3.utils.toHex(blockchain) }],
    });

}






/////////////////////////////////////////////////////////////////////////////////
  return (






<div className="SinglePool1">

<header className="App-header">

<div class="text text-start">

<nav class="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
  <div class="container-fluid">
  <a href={'https://cryptopatron.me/?lang=' + lang} class="navbar-brand"><img src={logotrans} class="img-fluid" width="60" height="auto"></img></a>
    <button class="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">






    <li class="nav-item">

   <a class="nav-link" onClick={HandleSetPoolInfo}><div class="pointer"><div class="pointer"><img src={info} class="img-fluid" width="20" height="20"></img> Info</div></div></a>
      
    </li>



    <li class="nav-item">

    {lang == 1 &&

    <a class="nav-link" onClick={HandleSetDepositsInfo}><div class="pointer"><div class="pointer"><img src={dividends} class="img-fluid" width="20" height="20"></img> Colaborate</div></div></a>}
    


    {lang == 2 &&
    
    
    <a class="nav-link" onClick={HandleSetDepositsInfo}><div class="pointer"><div class="pointer"><img src={dividends} class="img-fluid" width="20" height="20"></img> Colaborar</div></div></a>}
    
       

</li>





<li class="nav-item">

{lang == 1 &&

<a class="nav-link" onClick={HandleSetJackpotInfo}><div class="pointer"><div class="pointer"><img src={winner} class="img-fluid" width="20" height="20"></img> Lottery</div></div></a>}



{lang == 2 &&


<a class="nav-link" onClick={HandleSetJackpotInfo}><div class="pointer"><div class="pointer"><img src={winner} class="img-fluid" width="20" height="20"></img> Lotería</div></div></a>}

   

</li>





<li class="nav-item">

{(datafrontacc[3] == 1 && lang == 1 && isconnected > 0) &&

<a class="nav-link" onClick={HandleSetOwnerInfo}><div class="pointer"><div class="pointer"><img src={wallet} class="img-fluid" width="20" height="20"></img> Pool Wallet</div></div></a>}



{(datafrontacc[3] == 1 && lang == 2 && isconnected > 0) &&


<a class="nav-link" onClick={HandleSetOwnerInfo}><div class="pointer"><div class="pointer"><img src={wallet} class="img-fluid" width="20" height="20"></img> Billetera Pool</div></div></a>}   

</li>



        



<li class="nav-item">
{lang == 1 &&
<a class="nav-link" onClick={HandleSetSpanish}><div class="pointer">Español</div></a>}

{lang == 2 &&
<a class="nav-link" onClick={HandleSetEnglish}><div class="pointer">English</div></a>}
</li>






      </ul>
    </div>
  </div>
</nav>


</div>


<p></p>

<br></br>
<br></br>
<br></br>



 







<div class="card1">
<div class="card-body1">



<div class="card-footer">



{(decimalstoken == 0 || web4.utils.isAddress(addressunderlying) == false) &&
  <p>
  
  <div class="spinner-grow spinner-grow-sm text-primary"></div>
  <div class="spinner-grow spinner-grow-sm text-info"></div>
  <div class="spinner-grow spinner-grow-sm text-success"></div>
  
  </p>
  }



</div>


</div>
</div>




<p></p>



{ownerinfo == 1 &&

<div class="card">


<div class="card-header">
  


  
<div class="row">
<div class="col">


{lang == 1 &&
<p class="text text-start text-dark"><b>POOL WALLET</b></p>}

{lang == 2 &&
<p class="text text-start text-dark"><b>BILLETERA POOL</b></p>}



</div>

<div class="col">


<p class="text text-end">





{isconnected > 0  &&
<div class="dropdown">
  
  {lang == 1 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  
  


  {lang == 2 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  


  <div class="dropdown-content">



    
  </div>
</div>}





</p>


</div>




</div>



  
  
  </div>



<div class="card-body">


<div class="text text-start">


<div class="dropdown">
  <button class="dropbtn" onClick={HandleSetRefresh} ><img src={update} class="img-fluid" width="25" height="25"></img></button>
</div>

</div>


<div class="table-responsive">
    <table class="table bg-transparent">




<tbody>



{lang == 1 &&
<tr>
    <td><div class="text text-start text-dark"><b>EARNINGS</b></div></td>  
    <td><div class="text text-end text-dark"><b>TO COLLECT</b></div></td>  
    <td><div class="text text-end text-dark"><b>COLLECTED</b></div></td>  
  </tr>}

  {lang == 2 &&
<tr>
    <td><div class="text text-start text-dark"><b>INGRESOS</b></div></td>  
    <td><div class="text text-end text-dark"><b>A RETIRAR</b></div></td>  
    <td><div class="text text-end text-dark"><b>RETIRADOS</b></div></td>  
  </tr>}





  <tr>
    
    {lang == 1 &&
    <td><div class="text text-start">Interests {nametoken}</div></td>}

    {lang == 2 &&
    <td><div class="text text-start">Intereses {nametoken}</div></td>}
    
    
    <td><div class="text text-end">{ethers.utils.formatUnits(int[0], decimalstoken)}</div></td>
    
        
    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafront[5], decimalstoken)}</div></td>

      
  
  </tr>


  <tr>
  
    {lang == 1 &&
    <td><div class="text text-start">Donations {nametoken}</div></td>}
  
    {lang == 2 &&
    <td><div class="text text-start">Donaciones {nametoken}</div></td>}
  

    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafront[8], decimalstoken)}</div></td>

    

    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafront[7], decimalstoken)}</div></td>

    
  
    </tr>






</tbody>

</table>

</div>


<div class="input-group mb-3">  
  
  {lang == 1 &&
  <span class="input-group-text" id="basic-addon1"><b>{nametoken} Amount</b></span>}

  {lang == 2 &&
  <span class="input-group-text" id="basic-addon1"><b>Importe {nametoken}</b></span>}


    
    <Input type="number" name="name" value={collectinterest} onChange={ e => setCollectInterest(e.target.value) }  />
    </div>
    



    {mensaje.length > 0 &&


<div class="alert alert-danger" role="alert">
{mensaje}
</div>}




{mensajeok.length > 0 &&


<div class="alert alert-success" role="alert">
{mensajeok}
</div>}


  


{(isconnected > 0 && Number(blockchain) !== Number(chainid)) &&

<div class="text text-center">


{lang == 1 &&
    <button type="button" class="btn2" onClick={switchNetwork}>Switch Chain</button>}


    {lang == 2 &&
    <button type="button" class="btn2" onClick={switchNetwork}>Cambiar Red</button>}


</div>}







{(lang == 1 && Number(blockchain) === Number(chainid)) &&
    
    
    <p class="card-text">

    <div class="text text-center text-dark"><b>Withdraw Earnings</b></div>
<p></p>

  <button type="button" disabled={buttoncollectinterest} class="btn1" onClick={getUserInterest}>Interests</button>
      
<span> </span>


  <button type="button" disabled={buttoncollectdonation} class="btn1" onClick={getDonations}>Donations</button>

    </p>

  }


{(lang == 2 && Number(blockchain) === Number(chainid)) &&
  

    
    <p class="card-text">

    <div class="text text-center text-dark"><b>Retirar Ingresos</b></div>
<p></p>



  <button type="button" disabled={buttoncollectinterest} class="btn1" onClick={getUserInterest}>Intereses</button>
          
  <span> </span>
  
  <button type="button" disabled={buttoncollectdonation} class="btn1" onClick={getDonations}>Donaciones</button>

    </p>

  }




</div>
</div>}

















{(poolinfo == 1 && lang == 1) &&

<div class="card">
<div class="card-header">
  


<div class="row">
<div class="col">


<p class="text text-start text-dark"><b>POOL INFO</b></p>


</div>

<div class="col">


<p class="text text-end">





{isconnected > 0  &&
<div class="dropdown">
  
  {lang == 1 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  
  


  {lang == 2 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  


  <div class="dropdown-content">



    
  </div>
</div>}





</p>



  

</div>

</div>


  
  
</div>

<div class="card-body">


<div class="text text-start">

<div class="dropdown">
  <button class="dropbtn" onClick={HandleSetRefresh} ><img src={update} class="img-fluid" width="25" height="25"></img></button>
</div>

</div>


<div class="table-responsive">
    <table class="table bg-transparent">



  <tbody>
    




  <tr>
      <td><div class="text text-start">Pool Token</div></td>  
      <td align-right><div class="text text-end">{nametoken}</div></td>
    </tr>


    <tr>
      <td><div class="text text-start">Token Decimals</div></td>  
      <td align-right><div class="text text-end">{decimalstoken}</div></td>
    </tr>




    <tr>
      <td><div class="text text-start">Pool Network</div></td>  
      <td align-right><div class="text text-end">{namechain}</div></td>
    </tr>

    <tr>
      <td><div class="text text-start">Yield Source</div></td>  
      <td><div class="text text-end">AAVE</div></td>
    </tr>


    <tr>
      <td><div class="text text-start">Pool Address</div></td>  
    
    
      {web4.utils.isAddress(contractAddressPool1) == true &&
      <td><div class="text text-end"><a href={'https://' + theexplorer + '/address/' + contractAddressPool1} target="_blank" rel="noopener noreferrer"><small>{contractAddressPool1.substr(0,6)}...{contractAddressPool1.substr(-4)}</small></a></div></td>}
    
    
    
    </tr>

    <tr>
      <td><div class="text text-start">Yield Source Address</div></td>  
    
      {web4.utils.isAddress(addressyield) == true &&
      <td><div class="text text-end"><a href={'https://' + theexplorer + '/address/' + addressyield} target="_blank" rel="noopener noreferrer"><small>{addressyield.substr(0,6)}...{addressyield.substr(-4)}</small></a></div></td>}
    
    
    </tr>

    <tr>
      <td><div class="text text-start">Pool Token Address</div></td>  
      
      
      {web4.utils.isAddress(addressunderlying) == true &&
      <td><div class="text text-end"><a href={'https://' + theexplorer + '/address/' + addressunderlying} target="_blank" rel="noopener noreferrer"><small>{addressunderlying.substr(0,6)}...{addressunderlying.substr(-4)}</small></a></div></td>}
    
    
    
    </tr>





    <tr>
      <td><div class="text text-start">Owner Address</div></td>  
    
      {web4.utils.isAddress(owneraddress) == true &&
      <td><div class="text text-end"><a href={'https://' + theexplorer + '/address/' + owneraddress} target="_blank" rel="noopener noreferrer"><small>{owneraddress.substr(0,6)}...{owneraddress.substr(-4)}</small></a></div></td>}
    
    
    
    </tr>





    <tr>
      <td><div class="text text-start">Total Patrons</div></td>  
      <td align-right><div class="text text-end">{datafront[13]}</div></td>
    </tr>





    <tr>
      <td><div class="text text-start">Total Deposits in Yield Source {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(datafront[0], decimalstoken)}</div></td>
    
      

    </tr>



    <tr>
      <td><div class="text text-start">Interests Yield to Collect {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(int[0], decimalstoken)}</div></td>
    
         
    
    </tr>



    <tr>
      <td><div class="text text-start">Donations to Collect {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(datafront[8], decimalstoken)}</div></td>
    
      
    
    </tr>




    <tr>
      <td><div class="text text-start">Interests Yield Collected {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(datafront[5], decimalstoken)}</div></td>
    
      
    
    </tr>


    <tr>
      <td><div class="text text-start">Donations Collected {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(datafront[7], decimalstoken)}</div></td>
    
          
    
    </tr>


    <tr>
      <td><div class="text text-start">Random Number Generator</div></td>  
      
      {datafront[6] == 1 &&
      <td><div class="text text-end">CHAINLINK PRICE FEED</div></td>}
    
    {datafront[6] == 2 &&
      <td><div class="text text-end">BLOCKHASH</div></td>}
    
    </tr>




    <tr>
      <td><div class="text text-start">Lottery Drawn Every</div></td>  
      <td><div class="text text-end">{(datafront[14] / 86400).toFixed(0)} days</div></td>
    </tr>






  </tbody>

</table>
</div>






</div>
</div>}




{(poolinfo == 1 && lang == 2) &&


<div class="card">
<div class="card-header">
  



<div class="row">
<div class="col">


<p class="text text-start text-dark"><b>INFO DEL POOL</b></p>


</div>

<div class="col">


<p class="text text-end">



{isconnected > 0  &&
<div class="dropdown">
  
  {lang == 1 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  
  


  {lang == 2 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  


  <div class="dropdown-content">



    
  </div>
</div>}





</p>


</div>

  


</div>  
  
  
  
</div>
<div class="card-body">


<div class="text text-start">

<div class="dropdown">
  <button class="dropbtn" onClick={HandleSetRefresh} ><img src={update} class="img-fluid" width="25" height="25"></img></button>
</div>

</div>








<div class="table-responsive">
    <table class="table bg-transparent">


  <tbody>
    






  <tr>
      <td><div class="text text-start">Token del Pool</div></td>  
      <td align-right><div class="text text-end">{nametoken}</div></td>
    </tr>


    <tr>
      <td><div class="text text-start">Decimales del Token</div></td>  
      <td align-right><div class="text text-end">{decimalstoken}</div></td>
    </tr>




    <tr>
      <td><div class="text text-start">Red del Pool</div></td>  
      <td align-right><div class="text text-end">{namechain}</div></td>
    </tr>

    <tr>
      <td><div class="text text-start">Fuente de Ingresos</div></td>  
      <td><div class="text text-end">AAVE</div></td>
    </tr>


    <tr>
      <td><div class="text text-start">Direccion del Pool</div></td>  
    
    
      {web4.utils.isAddress(contractAddressPool1) == true &&
      <td><div class="text text-end"><a href={'https://' + theexplorer + '/address/' + contractAddressPool1} target="_blank" rel="noopener noreferrer"><small>{contractAddressPool1.substr(0,6)}...{contractAddressPool1.substr(-4)}</small></a></div></td>}
    
    
    
    </tr>

    <tr>
      <td><div class="text text-start">Direccion Fuente de Ingresos</div></td>  
    
      {web4.utils.isAddress(addressyield) == true &&
      <td><div class="text text-end"><a href={'https://' + theexplorer + '/address/' + addressyield} target="_blank" rel="noopener noreferrer"><small>{addressyield.substr(0,6)}...{addressyield.substr(-4)}</small></a></div></td>}
    
    
    </tr>

    <tr>
      <td><div class="text text-start">Direccion Token del Pool</div></td>  
      
      
      {web4.utils.isAddress(addressunderlying) == true &&
      <td><div class="text text-end"><a href={'https://' + theexplorer + '/address/' + addressunderlying} target="_blank" rel="noopener noreferrer"><small>{addressunderlying.substr(0,6)}...{addressunderlying.substr(-4)}</small></a></div></td>}
    
    
    
    </tr>





    <tr>
      <td><div class="text text-start">Direccion del Creador</div></td>  
    
      {web4.utils.isAddress(owneraddress) == true &&
      <td><div class="text text-end"><a href={'https://' + theexplorer + '/address/' + owneraddress} target="_blank" rel="noopener noreferrer"><small>{owneraddress.substr(0,6)}...{owneraddress.substr(-4)}</small></a></div></td>}
    
    
    
    </tr>




    <tr>
      <td><div class="text text-start">Total de Mecenas</div></td>  
      <td align-right><div class="text text-end">{datafront[13]}</div></td>
    </tr>




    <tr>
      <td><div class="text text-start">Total Depositos en Fuente de Ingresos {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(datafront[0], decimalstoken)}</div></td>
    
          
    
    </tr>



    <tr>
      <td><div class="text text-start">Intereses a Retirar {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(int[0], decimalstoken)}</div></td>
    
      
    
    </tr>


    <tr>
      <td><div class="text text-start">Donaciones a Retirar {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(datafront[8], decimalstoken)}</div></td>
    
          
    </tr>




    <tr>
      <td><div class="text text-start">Intereses Retirados {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(datafront[5], decimalstoken)}</div></td>
    
      
    </tr>


    <tr>
      <td><div class="text text-start">Donaciones Retiradas {nametoken}</div></td>  
    
      
      <td><div class="text text-end">{ethers.utils.formatUnits(datafront[7], decimalstoken)}</div></td>
    
      
    </tr>


    <tr>
      <td><div class="text text-start">Generador Número Random</div></td>  
      
      {datafront[6] == 1 &&
      <td><div class="text text-end">CHAINLINK PRICE FEED</div></td>}
    
    {datafront[6] == 2 &&
      <td><div class="text text-end">BLOCKHASH</div></td>}
    
    </tr>




    <tr>
      <td><div class="text text-start">Loteria Sorteada cada </div></td>  
      <td><div class="text text-end">{(datafront[14] / 86400).toFixed(0)} días</div></td>
    </tr>






  </tbody>

</table>
</div>






</div>
</div>}




{jackpotinfo == 1 &&

<div class="card">

<div class="card-header">

  
<div class="row">
<div class="col">


{lang == 1 &&
<p class="text text-start text-dark"><b>LOTTERY</b></p>}

{lang == 2 &&
<p class="text text-start text-dark"><b>LOTERIA</b></p>}



</div>

<div class="col">

<p class="text text-end">





{isconnected > 0  &&
<div class="dropdown">
  
  {lang == 1 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  
  


  {lang == 2 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  


  <div class="dropdown-content">



    
</div>
</div>}



</p>
 

</div>
</div>

</div>



<div class="card-body">

<div class="text text-start">

<div class="dropdown">
  <button class="dropbtn" onClick={HandleSetRefresh} ><img src={update} class="img-fluid" width="25" height="25"></img></button>
</div>

</div>


<div class="table-responsive">
    <table class="table bg-transparent">


<tbody>

<tr>
<td>


{(datafront[10] == 0 && lang == 1)  &&
<div class="text text-start">Next Prize {nametoken} # {parseInt(datafront[1]) + 1}</div>}

{(datafront[10] == 0 && lang == 2)  &&
<div class="text text-start">Proximo  Premio {nametoken} # {parseInt(datafront[1]) + 1}</div>}



{(datafront[10] > 0 && lang == 1)  &&
<div class="text text-start">Next Prize {nametoken} # {parseInt(datafront[1]) + 2}</div>}

{(datafront[10] > 0 && lang == 2)  &&
<div class="text text-start">Proximo Premio {nametoken} # {parseInt(datafront[1]) + 2}</div>}


</td>

<td>


<div class="text text-end">{ethers.utils.formatUnits(int[1], decimalstoken)}</div>



</td>  
</tr>





<tr>
<td>
{lang == 1  &&
  <div class="text text-start">Time Left</div>}

{lang == 2  &&
  <div class="text text-start">Tiempo Restante</div>}
</td>



{(lang == 1 && datafront[13] > 0) &&
<td><div class="text text-end"><AppTimer first={datafront[2]} /></div></td>}


{(lang == 2 && datafront[13] > 0) &&
<td><div class="text text-end"><AppTimer1 first={datafront[2]} /></div></td>}

{(lang == 1 && datafront[13] == 0) &&
<td><div class="text text-end">Countdown Stopped</div></td>}

{(lang == 2 && datafront[13] == 0) &&
<td><div class="text text-end">Cuenta Regresiva Detenida</div></td>}




</tr>



<tr>

{lang == 1  &&
<td><div class="text text-start">PRIZE {nametoken} # {parseInt(datafront[1]) + 1}</div></td>}

{lang == 2  &&
<td><div class="text text-start">PREMIO {nametoken} # {parseInt(datafront[1]) + 1}</div></td>}



<td><div class="text text-end">{ethers.utils.formatUnits(datafront[10], decimalstoken)}</div></td>



</tr>  

<tr>
<td>
{lang == 1  &&
  <div class="text text-start">Participants</div>}

{lang == 2  &&
  <div class="text text-start">Participantes</div>}
</td>

<td>
<div class="text text-end">{datafront[13]}</div>   
</td>
</tr>




<tr>
    
    
    {lang == 1 &&
    <td><div class="text text-start">Prizes Paid {nametoken}</div></td>}

    {lang == 2 &&
    <td><div class="text text-start">Premios Pagados {nametoken}</div></td>}
   
    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafront[11], decimalstoken)}</div></td>

    

  </tr>




<p></p>


  



</tbody>
</table>
</div>




{mensaje.length > 0 &&


<div class="alert alert-danger" role="alert">
{mensaje}
</div>}





{mensajeok.length > 0 &&
  

<div class="alert alert-success" role="alert">
{mensajeok}
</div>}






{(isconnected > 0 && Number(blockchain) !== Number(chainid)) &&

<div class="text text-center">


{lang == 1 &&
    <button type="button" class="btn2" onClick={switchNetwork}>Switch Chain</button>}


    {lang == 2 &&
    <button type="button" class="btn2" onClick={switchNetwork}>Cambiar Red</button>}


</div>}






{isconnected == 0 &&

<div class="text text-center">
<p class="card-text">

{lang == 1 &&
<button type="button" class="btn2" onClick={connectMetamask}>Connect Wallet</button>}

{lang == 2 &&
<button type="button" class="btn2" onClick={connectMetamask}>Conectar Wallet</button>}


</p>
</div>}







{(isconnected > 0 &&  Number(blockchain) === Number(chainid)) &&

<div class="text text-center">

{(datafront[3] == 1 && lang == 1) &&
    <button type="button" disabled={buttonspin} class="btn1" onClick={getPrize}>Spin Wheel</button>}
    {(datafront[3] == 1 && lang == 2) &&
    <button type="button" disabled={buttonspin} class="btn1" onClick={getPrize}>Girar Rueda</button>}


    {(datafront[3] == 2 && lang == 1) &&    
    <button type="button" disabled="true" class="btn1" onClick={getPrize}>Spin Wheel</button>}
    {(datafront[3] == 2 && lang == 2) &&    
    <button type="button" disabled="true" class="btn1" onClick={getPrize}>Girar Rueda</button>}



<span> </span>


{(jackpotinfo == 1 && datafront[10] > 0 && lang == 1) &&
<button type="button" disabled={buttonwinner} class="btn1" onClick={getAwinner}>Pick Winner</button>}

{(jackpotinfo == 1 && datafront[10] > 0 && lang == 2) &&
<button type="button" disabled={buttonwinner} class="btn1" onClick={getAwinner}>Elegir Ganador</button>}


<span> </span>


{(jackpotinfo == 1 && datafront[10] == 0 && lang == 1) &&
<button type="button" disabled="true" class="btn1" onClick={getAwinner}>Pick Winner</button>}

{(jackpotinfo == 1 && datafront[10] == 0 && lang == 2) &&
<button type="button" disabled="true" class="btn1" onClick={getAwinner}>Elegir Ganador</button>}


</div>}



</div>



<div class="card-footer">

<div class="table-responsive">
    <table class="table table-borderless bg-transparent">

<tbody>

{lang == 1 &&
<tr>
  <td><div class="text text-start text-dark"><small><b>ID</b></small></div></td>  
  
  <td><div class="text text-start text-dark"><small><b>Date</b></small></div></td>  
  
  <td><div class="text text-start text-dark"><small><b>Status</b></small></div></td>  
  
  <td><div class="text text-start text-dark"><small><b>Lucky Winner</b></small></div></td>  
  
  <td><div class="text text-end text-dark"><small><b>{nametoken} Amount</b></small></div></td>  
  </tr>}


  {lang == 2 &&
<tr>
  <td><div class="text text-start text-dark"><small><b>ID</b></small></div></td>  
  
  <td><div class="text text-start text-dark"><small><b>Fecha</b></small></div></td>  
  
  <td><div class="text text-start text-dark"><small><b>Status</b></small></div></td>  
  
  <td><div class="text text-start text-dark"><small><b>Ganador</b></small></div></td>  
  
  <td><div class="text text-end text-dark"><small><b>{nametoken} Importe</b></small></div></td>  
  </tr>}




{lotterydata}


</tbody>

</table>
</div>
</div>



</div>}







  {(depositsinfo == 1 && lang == 1) &&
  
  <div class="card">
<div class="card-header">
  



<div class="row">
<div class="col">


<p class="text text-start text-dark"><b>COLABORATE</b></p>


</div>

<div class="col">



<p class="text text-end">





{isconnected > 0  &&
<div class="dropdown">
  
  {lang == 1 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  
  


  {lang == 2 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  


  <div class="dropdown-content">



    
  </div>
</div>}



</p>









  
</div>
</div>
  
  
<p class="text text-start text-dark">{arr.thedesc}</p>  
  
  



  
  
  </div>
<div class="card-body">


<div class="text text-start">

<div class="dropdown">
  <button class="dropbtn" onClick={HandleSetRefresh} ><img src={update} class="img-fluid" width="25" height="25"></img></button>
</div>

</div>



  

  <div class="table-responsive">
    <table class="table bg-transparent">


<tbody>
  





  <tr>
    <td><div class="text text-start">Wallet Balance {nametoken}</div></td>  
  
    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafrontacc[1], decimalstoken)}</div></td>
  
      
  
  </tr>




  <tr>
    <td><div class="text text-start">Your Lendings {nametoken}</div></td>  
  
    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafrontacc[0], decimalstoken)}</div></td>
  
      
  
  </tr>

  <tr>
    <td><div class="text text-start">Your Donations {nametoken}</div></td>  
  
    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafrontacc[4], decimalstoken)}</div></td>
  
    
  
  
  </tr>

















</tbody>

</table>
</div>


<div class="input-group mb-3">  
  
  
  <span class="input-group-text" id="basic-addon1"><b>{nametoken} Amount</b></span>



    
    <Input type="number" name="name" value={amount} onChange={ e => setAmount(e.target.value) }  />
    </div>
    
  
    


{mensaje.length > 0 &&


<div class="alert alert-danger" role="alert">
{mensaje}
</div>}




{mensajeok.length > 0 &&
  

<div class="alert alert-success" role="alert">
{mensajeok}
</div>}


  
  
  





{(isconnected > 0 && Number(blockchain) === Number(chainid)) &&
  
  <div class="text text-center">


    <p class="text"><h6><a href='https://docs.cryptopatron.me/protocol/crypto-patron/disclaimer' target="_blank" rel="noopener noreferrer"><small>Disclaimer</small></a></h6></p>


    
    <p class="card-text">

  <button type="button" disabled={buttonwithdraw} class="btn" onClick={handleSetWithdraw}>Withdraw</button>
  
  <span> </span>
  
  <button type="button" disabled={buttondeposit} class="btn" onClick={handleSetDeposit}>Lend</button>
  
  <span> </span>
  
  <button type="button" disabled={buttondonation} class="btn" onClick={handleSetDepositDonation}>Donate</button>

    </p>


</div>}



{(isconnected > 0 && Number(blockchain) !== Number(chainid)) &&

<div class="text text-center">



    <button type="button" class="btn2" onClick={switchNetwork}>Switch Chain</button>

    

</div>}








{isconnected == 0 &&
<div class="text text-center">
<p class="card-text">


<button type="button" class="btn2" onClick={connectMetamask}>Connect Wallet</button>

</p>



</div>}







</div>
</div>}




{(depositsinfo == 1 && lang == 2) &&
  


  <div class="card">

<div class="card-header">
  



<div class="row">
<div class="col">  

<p class="text text-start text-dark"><b>COLABORAR</b></p>

  

</div>
<div class="col">




<p class="text text-end">




{isconnected > 0  &&
<div class="dropdown">
  
  {lang == 1 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  
  


  {lang == 2 &&
  <button class="dropbtn" onClick={disconnect} ><img src={wallet} class="img-fluid" width="30" height="30"></img> <b class="text text-dark"><small>{acc.substr(0,6)}...{acc.substr(-4)}</small></b></button>}
  


  <div class="dropdown-content">



    
  </div>
</div>}




</p>









</div>
</div>  

<p class="text text-start text-dark">{arr.thedesc}</p>



  
</div>

<div class="card-body">


<div class="text text-start">

<div class="dropdown">
  <button class="dropbtn" onClick={HandleSetRefresh} ><img src={update} class="img-fluid" width="25" height="25"></img></button>
</div>

</div>


<div class="table-responsive">
    <table class="table bg-transparent">


<tbody>











  <tr>
    <td><div class="text text-start">Balance Billetera {nametoken}</div></td>  
  
    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafrontacc[1], decimalstoken)}</div></td>
  
    
  
  
  </tr>

  <tr>
    <td><div class="text text-start">Sus Prestamos {nametoken}</div></td>  
  
    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafrontacc[0], decimalstoken)}</div></td>
  
    
  
  </tr>

  <tr>
    <td><div class="text text-start">Sus Donaciones {nametoken}</div></td>  
  
    
    <td><div class="text text-end">{ethers.utils.formatUnits(datafrontacc[4], decimalstoken)}</div></td>
  
      
  
  </tr>






</tbody>

</table>
</div>




<div class="input-group mb-3">  
  
  


  <span class="input-group-text" id="basic-addon1"><b>Importe {nametoken}</b></span>

    
    <Input type="number" name="name" value={amount} onChange={ e => setAmount(e.target.value) }  />
    </div>
    
  

  



{mensaje.length > 0 &&


<div class="alert alert-danger" role="alert">
{mensaje}
</div>}





{mensajeok.length > 0 &&
 

<div class="alert alert-success" role="alert">
{mensajeok}
</div>}


  
  



{(isconnected > 0 && Number(blockchain) === Number(chainid)) &&
<div class="text text-center">

    <p class="text"><h6><a href='https://docs.cryptopatron.me/protocol/crypto-patron/disclaimer' target="_blank" rel="noopener noreferrer"><small>Descargo de responsabilidad</small></a></h6></p>

    
  <p class="card-text">

  <button type="button" disabled={buttonwithdraw} class="btn" onClick={handleSetWithdraw}>Retirar</button>
  
  <span> </span>
  
  <button type="button" disabled={buttondeposit} class="btn" onClick={handleSetDeposit}>Prestar</button>
  
  <span> </span>
  
  <button type="button" disabled={buttondonation} class="btn" onClick={handleSetDepositDonation}>Donar</button>

    </p>


    </div>}




{(isconnected > 0 && Number(blockchain) !== Number(chainid)) &&

<div class="text text-center">


    <button type="button" class="btn2" onClick={switchNetwork}>Cambiar Red</button>


</div>}








{isconnected == 0 &&
<div class="text text-center">
<p class="card-text">


<button type="button" class="btn2" onClick={connectMetamask}>Conectar Wallet</button>

</p>



</div>}




</div>
</div>}




 

 

  

<div class="container-sm bg-transparent pt-3 text-center" ></div>






</header>  
</div>

      

);

}


export default SinglePoolV6;